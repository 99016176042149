import { QueryClient, dehydrate } from "@tanstack/react-query";
import Ad from "src/components/ads/Ad";
import StickyBottomAd from "src/components/ads/StickyBottomAd";
import ContentWrapper from "src/components/contentwrapper/ContentWrapper";
import FilmwebNews from "src/components/filmwebnews/FilmwebNews";
import InfoBox from "src/components/infobox/InfoBox";
import PopularMoviesList, { POP_MOVIES_QUERY } from "src/components/popularmovieslist/PopularMoviesList";
import KinoprogramLoader from "src/components/program/KinoprogramLoader";
import PosterGrid from "src/components/program/PosterGrid";
import KinoprogramSeo from "src/components/seo/KinoprogramSeo";
import StreamingSearchBox from "src/components/streaming/StreamingSearchBox";
import theme from "src/components/themes/DefaultTheme";
import { buildFilminfoQueryKey, filminfoRequest } from "src/lib/client/useFilminfo";
import AdWrapper, { AdUnit } from "src/lib/contexts/AdContext";
import { LocationWrapper } from "src/lib/contexts/LocationContext";
import ProgramDatesWrapper from "src/lib/contexts/ProgramDatesWrapper";
import ProgramFilterWrapper from "src/lib/contexts/ProgramFilterWrapper";
import SelectedCinemaWrapper from "src/lib/contexts/SelectedCinemaContext";
import { FRONTPAGE_NEWS, filterOtherNews } from "src/lib/groq";
import { setDefaultCacheHeaders } from "src/lib/server/cache";
import { ICommonProps, IQueryResultWithNews, getServerData, sanityQuery } from "src/lib/server/sanity";
import styled from "src/lib/styles/css";
import IFilmnytt from "src/lib/types/Filmnytt";
import type { GetServerSideProps } from "next";
import { groq } from "next-sanity";
import { useOverriddenAdunits } from "src/lib/server/ads";



//#region [Other] getServerSideProps
export const getServerSideProps: GetServerSideProps = getServerData(async (context) => {

    setDefaultCacheHeaders(context);
    // NOTE: Prefetching popular movies prevents page jumping
    const queryClient = new QueryClient();
    const prefetchPopMoviesPromise = queryClient.prefetchQuery({
        queryKey: buildFilminfoQueryKey(POP_MOVIES_QUERY),
        queryFn: filminfoRequest
    });

    const query = context.query;
    let location: string | null = null;
    if (query.location) {
        if (Array.isArray(query.location)) {
            if (query.location.length > 0) {
                location = query.location[1];
            }
        } else {
            location = query.location as string;
        }
    }

    const cmsDataPromise = sanityQuery<IQueryResultWithNews<unknown>>(PAGE_QUERY);

    const [cmsData] = await Promise.all([cmsDataPromise, prefetchPopMoviesPromise]);

    let otherNews: IFilmnytt[] | null | undefined = cmsData?.news ?? null;
    if (otherNews) {
        // article types other than "filmomtale" willbe a null element in the array, therefore we must filter it
        otherNews = filterOtherNews(otherNews)?.slice(0, 3);
    }

    return {
        props: {
            serverLocation: location,
            news: otherNews ?? null,
            dehydratedState: dehydrate(queryClient)
        }
    }

});
//#endregion

//#region [Props]
type KinoprogramProps = {
    serverLocation: string | null;
    news: IFilmnytt[] | null;
} & ICommonProps;
//#endregion

//#region [Component]
export default function Kinoprogram({ serverLocation, news, appData, hasSponsor, adUnits, sponsorClickUrl, sponsorTopImageDesktop, sponsorTopImageMobile, hasSponsorBg }: KinoprogramProps) {
    const finalAdUnits = useOverriddenAdunits(ADS, adUnits);
    return <SProgramSection>
        <KinoprogramSeo serverLocation={serverLocation} />
        <SInfoBox messageBanner={appData?.settings} />
        <SContentWrapper className={hasSponsorBg ? "noBgColor": undefined}>
            <SPopularMovies />

            <AdWrapper ads={finalAdUnits}>
                <LocationWrapper>
                    <SelectedCinemaWrapper>
                        <ProgramDatesWrapper>
                            <ProgramFilterWrapper>
                                <KinoprogramLoader hasSponsor={hasSponsor} sponsorClickUrl={sponsorClickUrl} sponsorTopImageDesktop={sponsorTopImageDesktop} sponsorTopImageMobile={sponsorTopImageMobile} hasSponsorBg={hasSponsorBg}>
                                    <PosterGrid /> {/** Movies will be populated by the movieloader */}
                                </KinoprogramLoader>
                            </ProgramFilterWrapper>
                        </ProgramDatesWrapper>
                    </SelectedCinemaWrapper>
                </LocationWrapper>

                <FilmwebNews articles={news} />
                <SStreamingSearchBox showLogo toolbox={appData?.streamingToolbox} />
                <SAd targetId="FW_bunn2_program_v2" removeIfNoAd supportsFixedAd pageHasStickyMenu />
                <StickyBottomAd isPackshot={false} />
            </AdWrapper>

            <SPopularMovies />
        </SContentWrapper>
    </SProgramSection>;
}
//#endregion

const ADS: AdUnit[] = [
    { auId: '000000000007ec74', auW: '2000', auH: '300', targetId: 'FW_topp_program_v2' },
    //{ auId: '3880d9', auW: '2000', auH: '300', targetId: 'FW_topp_program_v2' }, // SAN-509
    { auId: '000000000007ec76', auW: '2000', auH: '300', targetId: 'FW_bunn1_program_v2' },
    { auId: '000000000007ec78', auW: '2000', auH: '300', targetId: 'FW_bunn2_program_v2' },
    { auId: '000000000007ec75', auW: '2000', auH: '300', targetId: 'FW_midt1_program_v2' },
    { auId: '000000000007f0fd', auW: '2000', auH: '300', targetId: 'FW_midt2_program_v2' },
    { auId: '000000000007f0fe', auW: '2000', auH: '300', targetId: 'FW_midt3_program_v2' },
    { auId: '0000000000248ebf', auW: '1px', auH: '1px', targetId: 'FW_sg_search_box' },
    { auId: '00000000001fc39d', auW: '100vw', auH: '15.625vw', targetId: 'FW_bottomSticky', requestMode: "hasTarget" }

];

//#region [Other] GROQ
const PAGE_QUERY: string = groq`
{
	"news": ${FRONTPAGE_NEWS}
}
`;
//#endregion

//#region [Styles]
const SInfoBox = styled(InfoBox)`
	margin: ${theme.maxContentPadding};
	width: calc(100% - 2 * ${theme.maxContentPadding});
	@media ${theme.mq.desktop} {
		margin: 20px auto;
		width: auto;
	}

	max-width: calc(${theme.maxContentWidth}px - 2 * ${theme.maxContentPadding});
`;

const SProgramSection = styled.section`
	padding-bottom: 50px;
	margin-top: 10px;
	@media ${theme.mq.desktop} {
		margin-top: 35px;
	}
`;

const SContentWrapper = styled(ContentWrapper)`
    @media ${theme.mq.desktop} {
        &.noBgColor {
            background: transparent;
        }
    }
`;

const SPopularMovies = styled(PopularMoviesList)`
	margin-left: ${theme.maxContentPadding};

	@media ${theme.mq.desktop} {
		margin-left: 0;
	}

	margin-bottom: 15px;
`;

const SStreamingSearchBox = styled(StreamingSearchBox)`
	margin: 15px 0 0 0;
	@media ${theme.mq.desktop} {
		margin-top: 35px;
	}
`;

const SAd = styled(Ad)`
	margin-top: 15px;
	margin-bottom: 15px;

	@media ${theme.mq.desktop} {
		margin-top: 35px;
		margin-bottom: 35px;
	}
`;
//#endregion