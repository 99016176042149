import classNames from "classnames";
import { SanityReference } from "next-sanity";
import { CSSProperties } from "react";
import TrackingPixel from "src/components/ads/TrackingPixel";
import CmsImageForAssetRef from "src/components/images/CmsImageForAssetRef";
import { getOrgSize } from "src/components/images/imageFunctions";
import theme from "src/components/themes/DefaultTheme";
import styled, { css } from "src/lib/styles/css";

//#region [Props]
type ProgramControlsSponsorProps = {
    isSticky?: boolean;
    className?: string;
    sponsorClickUrl?: string | null;
    sponsorTopImageDesktop?: SanityReference[] | null;
    sponsorTopImageMobile?: SanityReference[] | null;
};
//#endregion

//const clickDestination = "https://www.coca-cola.com/no/no/offerings/fanta-appelsin?utm_source=Filmweb&utm_medium=Sponsorship&utm_campaign=Filmweb_Fanta_Sponsorship";

/**
 * NOTE:
 * sponsorTopImage[0] benyttes når programmenyen ikke er sticky
 * sponsorTopImage[1] benyttes når programmenyen er sticky
 *
 */

//#region [Component]
export default function ProgramControlsSponsor({ isSticky, className, sponsorClickUrl, sponsorTopImageDesktop, sponsorTopImageMobile }: ProgramControlsSponsorProps) {
    if ((!sponsorTopImageDesktop || sponsorTopImageDesktop.length === 0) && (!sponsorTopImageMobile || sponsorTopImageMobile.length === 0)) {
        return null;
    }

    const orgSizesDesktop = sponsorTopImageDesktop?.map(ref => getOrgSize(ref._ref));
    const orgSizesMobile = sponsorTopImageMobile?.map(ref => getOrgSize(ref._ref));

    let cssStyle: any = {};
    const hasDesktopSticky = (orgSizesDesktop?.length ?? 0) > 1;
    const hasMobileSticky = (orgSizesMobile?.length ?? 0) > 1;

    if ((orgSizesDesktop?.length ?? 0) > 0) {
        cssStyle["--aspect-ratio-d"] = `${orgSizesDesktop![0].width} / ${orgSizesDesktop![0].height}`;
        cssStyle["--sponsor-width-d"] = `${orgSizesDesktop![0].width}px`;
    }
    if ((orgSizesMobile?.length ?? 0) > 0) {
        cssStyle["--aspect-ratio-m"] = `${orgSizesMobile![0].width} / ${orgSizesMobile![0].height}`;
    }

    if (hasDesktopSticky) {
        cssStyle["--aspect-ratio-d-sticky"] = `${orgSizesDesktop![1].width} / ${orgSizesDesktop![1].height}`;
    }
    if (hasMobileSticky) {
        cssStyle["--aspect-ratio-m-sticky"] = `${orgSizesMobile![1].width} / ${orgSizesMobile![1].height}`;
    }

    return <SSponsor as={!!sponsorClickUrl ? "a" : "div"} href={!!sponsorClickUrl ? sponsorClickUrl : undefined} target="_blank" style={cssStyle as CSSProperties} className={classNames(isSticky ? "isSticky" : undefined, className)}>
        {!!sponsorTopImageMobile && <>
            {((hasMobileSticky && !isSticky) || (!hasMobileSticky)) && <SCmsImageForAssetRefMobile assetRef={sponsorTopImageMobile![0]._ref} alt="Sponsor" />}
            {(hasMobileSticky && isSticky) && <SCmsImageForAssetRefMobile assetRef={sponsorTopImageMobile![1]._ref} alt="Sponsor" />}
        </>}

        {!!sponsorTopImageDesktop && <>
            {((hasDesktopSticky && !isSticky) || (!hasDesktopSticky)) && <SCmsImageForAssetRefDesktop assetRef={sponsorTopImageDesktop![0]._ref} alt="Sponsor" />}
            {(hasDesktopSticky && isSticky) && <SCmsImageForAssetRefDesktop assetRef={sponsorTopImageDesktop![1]._ref} alt="Sponsor" />}
        </>}

        <TrackingPixel targetId="SponsorTrackingPixel" />
    </SSponsor>;
}
//#endregion

//#region [Styles]

const SSponsor = styled.a`
    display: block;
    background-repeat: no-repeat;
    background-size: contain;

    aspect-ratio: var(--aspect-ratio-m);
    &.isSticky {
        aspect-ratio: var(--aspect-ratio-m-sticky, var(--aspect-ratio-m));
    }

    @media ${theme.mq.desktop} {
        flex: 0 0 var(--sponsor-width-d) !important;
        aspect-ratio: var(--aspect-ratio-d) !important;

        &.isSticky {
            aspect-ratio: var(--aspect-ratio-d-sticky, var(--aspect-ratio-d)) !important;
        }
    }
`;

const SCmsImageForAssetRefDesktop = styled(CmsImageForAssetRef)`
    display: none;
    @media ${theme.mq.desktop} {
        display: block;
    }

`;

const SCmsImageForAssetRefMobile = styled(CmsImageForAssetRef)`
    display: block;
    @media ${theme.mq.desktop} {
        display: none;
    }

`;
//#endregion