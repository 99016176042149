"use client"
import Button from "src/components/button/Button";
import LoadingIndicator from "src/components/loadingindicator/LoadingIndicator";
import NoLocation from "src/components/program/NoLocation";
import ProgramControls from "src/components/program/ProgramControls";
import { format, isPast, isThisWeek } from "date-fns";
import locale from "date-fns/locale/nb";
import { gql } from "graphql-request";
import { useFilminfo } from "src/lib/client/useFilminfo";
import withScrollRestoration, { SupportsScrollRestoration } from "src/lib/client/withScrollRestoration";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import { useProgramDatesContext } from "src/lib/contexts/ProgramDatesWrapper";
import { ProgramFilterStateType, useProgramFilterContext } from "src/lib/contexts/ProgramFilterWrapper";
import { MovieType, ShowType } from "src/lib/movieinfo/movieinfotypes";
import styled from 'src/lib/styles/css';
import { type ILoaderMoviesProps } from 'src/lib/types/filminfo/ILoaderMoviesProps';
import { ProgramMovie } from "src/lib/types/filminfo/ProgramMovie";
import { intersection } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { SanityReference } from "next-sanity";

//#region [Props]
type KinoprogramLoaderProps = {
    children: React.ReactNode;
    hasSponsor: boolean;
    sponsorClickUrl?: string | null;
    sponsorTopImageDesktop?: SanityReference[] | null;
    sponsorTopImageMobile?: SanityReference[] | null;
    hasSponsorBg?: boolean;
} & SupportsScrollRestoration;
//#endregion

//#region [Component]
function KinoprogramLoader({ children, isReadyForScrollRestoration, hasSponsor, sponsorClickUrl, sponsorTopImageDesktop, sponsorTopImageMobile, hasSponsorBg }: KinoprogramLoaderProps) {
    const locationContext = useLocationContext();
    const selectedDateContext = useProgramDatesContext();
    const filterContext = useProgramFilterContext();
    const containerRef = useRef<HTMLDivElement>(null);

    const [filteredMovies, setFilteredMovies] = useState<FilteredMovies | null>(null);

    const { fiLoading: isLocationDataLoading, fiData: locData } = useFilminfo(
        LOCATION_DATA_QUERY,
        { location: locationContext.location, excludeMovieIdPrefixes: ["KUL"] },
        { active: !!locationContext.location, staleTime: 10 * 60 * 1000 }
    );

    const { fiLoading: _isShowsLoading, fiData: _showData } = useFilminfo(
        PROGRAM_FOR_DATE_QUERY,
        { location: locationContext.location, date: selectedDateContext.selectedDate ? format(selectedDateContext.selectedDate, 'yyyy-MM-dd') : null },
        { active: !!selectedDateContext.selectedDate && !!locationContext.location, staleTime: 10 * 60 * 1000 }
    );

    useEffect(() => {
        if (!isLocationDataLoading) {
            const dates = locData?.showQuery?.getShowDates?.map(date => new Date(date!.date));
            if ((dates?.length ?? 0) > 0) {
                selectedDateContext.setAvailableDates(dates as Date[]);
            } else {
                selectedDateContext.setAvailableDates(null);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locData, isLocationDataLoading]);

    useEffect(() => {
        if (!_isShowsLoading) {
            if ((_showData?.movieQuery?.getCurrentMovies?.length ?? 0) > 0) {
                const _filteredMovies = _showData!.movieQuery!.getCurrentMovies!.filter(movie => {
                    // remove culture shows
                    if (movie?.mainVersionId?.startsWith("KUL")) {
                        return false;
                    }

                    // remove movies with all shows in the past
                    if (movie?.shows?.every(show => isPast(new Date(show?.showStart!)))) {
                        return false;
                    }
                    return true;
                }) as MovieType[];

                let _transformedMovies = _filteredMovies.map(movie => {
                    const s = movie.shows?.map(show => ({
                        ...(show as ShowType),
                        dateShowStart: new Date(show?.showStart!),
                        perspectives: ensureAtLeast2DIsPresent(show!)
                    }))!;
                    const tMovie: ProgramMovie = {
                        ...movie,
                        isPremieringThisWeek: isPremieringThisWeek(movie.premiere),
                        showsWithDate: s,
                        filteredShows: s
                    };
                    return tMovie;
                });

                const filterResult = filterMoviesAndShows(_transformedMovies, filterContext);

                setFilteredMovies(filterResult);
                isReadyForScrollRestoration?.(true);
            } else {
                setFilteredMovies(null);
                isReadyForScrollRestoration?.(true);
            }
        }
    }, [_showData, filterContext, isReadyForScrollRestoration, _isShowsLoading]);


    return <SProgramContainer ref={containerRef}>
        <ProgramControls hasSponsor={hasSponsor} sponsorClickUrl={sponsorClickUrl} sponsorTopImageDesktop={sponsorTopImageDesktop} sponsorTopImageMobile={sponsorTopImageMobile} hasSponsorBg={hasSponsorBg}/>
        {(isLocationDataLoading || _isShowsLoading) && <LoadingIndicator />}
        {!(isLocationDataLoading || _isShowsLoading) && <>
            {React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { movies: filteredMovies?.movies } as ILoaderMoviesProps);
                }
                return child;
            })}

            {((filteredMovies?.numFilteredMovies ?? 0) > 0 || (filteredMovies?.numFilteredShows ?? 0) > 0) && <SFilterInfo>
                <p>Dine filterinnstillinger skjuler {filteredMovies!.numFilteredMovies ? `${filteredMovies!.numFilteredMovies} filmer` : ""} {filteredMovies!.numFilteredMovies && filteredMovies!.numFilteredShows ? "og" : ""} {filteredMovies!.numFilteredShows ? `${filteredMovies!.numFilteredShows} forestillinger` : ""}</p>
                <Button text="Nullstill filter" onClick={() => { filterContext.reset(); containerRef.current?.scrollIntoView({ behavior: "smooth" }) }} />
            </SFilterInfo>}
        </>}
        {!isLocationDataLoading && !locationContext.location && <NoLocation />}
    </SProgramContainer>;
}
//#endregion

export default withScrollRestoration(KinoprogramLoader, 500);

//#region [Other] Helpers
type FilteredMovies = {
    movies: ProgramMovie[];
    numFilteredMovies: number;
    numFilteredShows: number;
}

function filterMoviesAndShows(movies: ProgramMovie[], filterContext: ProgramFilterStateType): FilteredMovies {

    let numMovies = 0;
    let numShows = 0;
    let genres = filterContext.genres || [];
    if (genres && genres.includes("Familiefilm")) {
        genres.push("Barnefilm");
    }
    let movs = movies.filter(mov => {
        // first apply filters that will match whole movies
        if (filterContext.highlightPremieres && !mov.isPremieringThisWeek) {
            numMovies++;
            numShows += mov.shows?.length ?? 0;
            return false;
        }

        if (genres.length) {
            // genre filtering is on, but the movie does not have any genres, OR none of the genres match
            if (!(mov.genres.length && intersection(mov.genres, genres).length)) {
                numMovies++;
                numShows += mov.shows?.length ?? 0;
                return false;
            }
        }

        return true;
    });

    movs = movs.map(oldMov => {
        const mov = { ...oldMov };
        // filter shows for movie
        mov.filteredShows = mov.showsWithDate;
        if (filterContext.perspective.length) {
            // Not having any dimensions selected is the same as haveing all dimensions selected:
            mov.filteredShows = mov.filteredShows.filter(s => !!intersection(s.perspectives, filterContext.perspective).length);
        }

        if (filterContext.selectedCinemas?.length) {
            // not haveing any cinemas selected is the same as having all cinemas selected:
            mov.filteredShows = mov.filteredShows.filter(s => filterContext.selectedCinemas.includes(s.theaterName));
        }

        if (filterContext.timeIntervals?.length) {
            mov.filteredShows = mov.filteredShows.filter(s => filterContext.timeIntervals.some(interval => interval(s.dateShowStart)));
        }
        if (filterContext.highlightKinoklubb) {
            mov.filteredShows = mov.filteredShows.filter(s => {
                return mov.isKinoklubb && s.isKinoklubb;
            });
        }
        // if all shows have not been filtered away, the movie should be shown
        numShows += (mov.shows?.length ?? 0) - mov.filteredShows.length;
        if (!mov.filteredShows.length) {
            numMovies++;
        }
        return mov;
    });

    movs = movs.filter(mov => {
        return (mov.filteredShows?.length ?? 0) !== 0;
    });



    return { movies: movs, numFilteredMovies: numMovies, numFilteredShows: numShows };

}

function isPremieringThisWeek(premiere: string) {
    if (!premiere || premiere === "0001-01-01T00:00:00") {
        return false;
    }
    return isThisWeek(new Date(premiere), { locale, weekStartsOn: 1 });
}

/**
 * Make sure every show has the correct dimensions. A show with no dimension tags is 2D. These dimensions tags will be stored in show.dims
 * @param {Show} show the show to mark
 */
function ensureAtLeast2DIsPresent(show: ShowType): string[] {
    let perspectives = show.versionTags?.filter(t => t!.tag === "3D" || t!.tag === "2D").map(t => t!.tag);
    if ((perspectives?.length ?? 0) === 0) {
        perspectives = ["2D"];
    }

    return perspectives!;
}
//#endregion


//#region [Other] Queries
const LOCATION_DATA_QUERY = gql`
query ($location: String, $excludeMovieIdPrefixes: [String]) {
	showQuery {
		getShowDates (location: $location, excludeMovieIdPrefixes: $excludeMovieIdPrefixes) {
			date
		}
	}
}`;

const PROGRAM_FOR_DATE_QUERY = gql`
query($date: String, $location: String) {
	movieQuery {
		getCurrentMovies(date: $date, location: $location, removePastShows: true) {
			__typename
			articleId
			mainVersionId
			premiere
			title
			isKinoklubb
			genres
			lengthInMinutes
			rating
			sanityImagePosterUrl
			shows {
				__typename
				firmName
				showStart
				screenName
				showType
				isKinoklubb
				ticketSaleUrl
				theaterName
				movieVersionId
				versionTags {
					tag
					type
				}
			}
		}

	}
}`;
//#endregion



//#region [Styles]
const SProgramContainer = styled.div`
`;

const SFilterInfo = styled.div`
	color: var(--textcolor);
	text-align: center;
	margin-bottom: 72px;
`;
//#endregion